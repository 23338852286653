import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97')
];

export const server_loads = [0,2,7,12,13];

export const dictionary = {
		"/": [~14],
		"/app": [~17,[2]],
		"/app/academic/class-placement": [~18,[2,3]],
		"/app/academic/classes": [~19,[2,3]],
		"/app/academic/classes/[classId]": [~20,[2,3]],
		"/app/academic/continuation": [~21,[2,3]],
		"/app/academic/overview": [~22,[2,3]],
		"/app/admin/academic-years": [~23,[2,4]],
		"/app/admin/academic-years/[academicYearId]": [~24,[2,4]],
		"/app/admin/delete-students": [25,[2,4]],
		"/app/admin/users": [~26,[2,4]],
		"/app/admin/users/[staffId]": [~27,[2,4]],
		"/app/admin/value-centers": [~28,[2,4]],
		"/app/admin/value-centers/[valueCenterId]": [~29,[2,4]],
		"/app/admission/admission-groups": [30,[2,5]],
		"/app/admission/admission-steps": [~31,[2,5]],
		"/app/admission/admission-steps/[stepId]": [~32,[2,5]],
		"/app/admission/announcements": [~33,[2,5]],
		"/app/admission/announcements/[announcementId]": [~34,[2,5]],
		"/app/admission/overview": [~35,[2,5]],
		"/app/admission/registrations": [~36,[2,5]],
		"/app/admission/reports": [37,[2,5]],
		"/app/admission/schools": [~38,[2,5]],
		"/app/admission/schools/[schoolId]": [~39,[2,5]],
		"/app/admission/students": [~40,[2,5]],
		"/app/admission/students/form/existing": [~41,[2,5]],
		"/app/admission/students/form/new": [~42,[2,5]],
		"/app/finance/bills": [43,[2,6]],
		"/app/finance/bills/bill-templates": [~44,[2,6]],
		"/app/finance/bills/insert-bills": [~45,[2,6]],
		"/app/finance/monitoring": [~46,[2,6]],
		"/app/finance/overview": [~47,[2,6]],
		"/app/finance/payment-categories": [~48,[2,6]],
		"/app/finance/payment-categories/[paymentCategoryId]/discounts": [~49,[2,6]],
		"/app/finance/payment-categories/[paymentCategoryId]/discounts/[discountId]": [~50,[2,6]],
		"/app/finance/payment-categories/[paymentCategoryId]/form": [~51,[2,6]],
		"/app/finance/payment-categories/[paymentCategoryId]/payment-types": [~52,[2,6]],
		"/app/finance/payment-categories/[paymentCategoryId]/payment-types/[paymentTypeId]": [~53,[2,6]],
		"/app/finance/reports": [54,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-day": [~55,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-month": [~56,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/bills-per-student": [~57,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/receivables-per-student": [~58,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-day": [~59,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-month": [~60,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-student": [~61,[2,6]],
		"/app/finance/reports/[startDate]/[endDate]/revenue-per-transaction": [~62,[2,6]],
		"/app/finance/students": [~63,[2,6]],
		"/app/guardian/[guardianId]": [64,[2,7]],
		"/app/guardian/[guardianId]/biodata": [~65,[2,7]],
		"/app/home/announcements": [~66,[2,8]],
		"/app/home/announcements/[announcementId]": [~67,[2,8]],
		"/app/internal/document-categories": [~68,[2,9]],
		"/app/internal/document-categories/[documentCategoryId]": [~69,[2,9]],
		"/app/internal/document-tags": [~70,[2,9]],
		"/app/internal/document-tags/[documentTagId]": [~71,[2,9]],
		"/app/internal/documents": [~72,[2,9]],
		"/app/internal/documents/[documentTypeId]": [~73,[2,9]],
		"/app/internal/documents/[documentTypeId]/documents": [~74,[2,9]],
		"/app/internal/documents/[documentTypeId]/documents/[documentId]": [~75,[2,9]],
		"/app/internal/documents/[documentTypeId]/documents/[documentId]/viewer": [~76,[2,9]],
		"/app/people/guardians": [~77,[2,10]],
		"/app/people/guardians/new": [~78,[2,10]],
		"/app/people/staffs": [~79,[2,10]],
		"/app/people/students": [~80,[2,10]],
		"/app/settings/2fa": [~81,[2,11]],
		"/app/settings/2fa/disable": [~82,[2,11]],
		"/app/settings/2fa/verify": [~83,[2,11]],
		"/app/settings/change-password": [~84,[2,11]],
		"/app/staff/[staffId]": [85,[2,12]],
		"/app/staff/[staffId]/biodata": [~86,[2,12]],
		"/app/student/[studentId]": [87,[2,13]],
		"/app/student/[studentId]/bills": [~88,[2,13]],
		"/app/student/[studentId]/bills/[billId]/discounts": [89,[2,13]],
		"/app/student/[studentId]/bills/[billId]/form": [~90,[2,13]],
		"/app/student/[studentId]/biodata": [~91,[2,13]],
		"/app/student/[studentId]/invoices": [~92,[2,13]],
		"/app/student/[studentId]/invoices/[invoiceId]/approve": [~93,[2,13]],
		"/app/student/[studentId]/invoices/[invoiceId]/reallocate": [~94,[2,13]],
		"/app/student/[studentId]/invoices/[invoiceId]/receipt/pdf": [~95],
		"/app/student/[studentId]/others": [~96,[2,13]],
		"/app/student/[studentId]/others/[noteId]": [~97,[2,13]],
		"/(auth)/login": [~15],
		"/(auth)/verify": [~16]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';